import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import AboutInvestment from "../components/aboutInvestment"
import ApartmentInfoSection from "../components/apartmentInfoSection"
import LocationMapHolder from "../components/locationMapHolder"
import ModalPromo from "../components/modalPromo/ModalPromo"

const IndexPage = ({ data: { acfPage } }) => {
  const acf = acfPage.edges[0].node.acf

  return (
    <Layout>
      <SEO title="Strona główna" />
      <Hero />
      <AboutInvestment />
      <ApartmentInfoSection />
      <LocationMapHolder plainMap={false} />
      <ModalPromo acf={acf} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    acfPage: allWordpressAcfPages(filter: { wordpress_id: { eq: 980 } }) {
      edges {
        node {
          acf {
            popup_enabled
            popup_content
            popup_button_link
          }
        }
      }
    }
  }
`
