import "./modal-promo.scss"

import React, { useState } from "react"
import { withCookies } from "react-cookie"

const ModalPromo = ({ acf, cookies }) => {
  const [modal, setModal] = useState(true)

  return (
    <>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      {!cookies.cookies.popup && modal && acf.popup_enabled ? (
        <div className="modal-promo">
          <div className="modal-promo__wrapper">
            <button
              className="modal-promo__close"
              onClick={e => {
                e.preventDefault()
                cookies.set("popup", true, { path: "/", maxAge: 86400 })
                setModal(false)
              }}
            >
              Close
            </button>
            <div
              className="modal-promo__inner"
              dangerouslySetInnerHTML={{ __html: acf.popup_content }}
            />
            <div className="modal-promo__image">
              <img
                className="img-fluid"
                src={require("../../assets/img/modal-promo-ico.svg")}
                alt=""
              />
            </div>
            <a
              href={acf.popup_button_link}
              target="_blank"
              rel="noreferrer"
              className="modal-promo__cta"
            >
              Więcej{" "}
              <img
                src={require("../../assets/img/ico-button-arrow.svg")}
                alt=""
              />
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default withCookies(ModalPromo)
