import React from "react"
import { Fade } from "react-awesome-reveal"

const Hero = () => (
  <section className="hero">
    <div className="container">
      <Fade delay={150} triggerOnce>
        <div className="row">
          <div className="col-12 col-lg-4 col-xxl-5 offset-lg-8 offset-xxl-7 hero__holder">
            <h1 className="hero__title">
              Japoński zakątek <br />
              <span>w sercu Krakowa</span>
            </h1>
            {
              // <p className="hero__subtitle subtitle big-subtitle">
              //   Promocja Walentynkowa - 300 zł m2
              // </p>
            }
          </div>
        </div>
      </Fade>
      <span className="hero__building"></span>
      <span className="hero__tree"></span>
    </div>
  </section>
)

export default Hero
