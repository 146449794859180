import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"

const ApartmentInfoSection = () => (
  <section className="apartment-info-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-xxl-10 offset-xxl-1">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="apartment-info-section__title">Mieszkania</h2>
            <p className="subtitle apartment-info-section__subtitle">
              Wybierz mieszkanie najlepsze dla siebie
            </p>
          </Fade>
        </div>
      </div>
      <div className="row apartment-info-section__content-holder">
        <div className="col-12 col-xl-4 col-xxl-4 offset-xxl-1 apartment-info-section__content-row">
          <Fade delay={150} direction={"left"} triggerOnce>
            <div className="apartment-info-section__content">
              <div className="content__first-row">
                <div className="content__item first-row__item mb-30">
                  <p className="h3 item__title">Ilość pokoi</p>
                  <p className="big-number item__content">1-5</p>
                </div>
                <div className="content__item first-row__item mb-30">
                  <p className="h3 item__title">Piętro</p>
                  <p className="big-number item__content">0-5</p>
                </div>
              </div>
              <div className="content__item mb-70">
                <p className="h3 item__title">Metraż</p>
                <p className="big-number item__content">
                  28m<sup>2</sup> - 94m<sup>2</sup>
                </p>
              </div>
              {
                // <div className="content__item mb-70">
                //   <p className="h3 item__title">Cena brutto</p>
                //   <p className="big-number item__content">
                //     od 383 600 <span>PLN</span>
                //   </p>
                // </div>
              }
              <Link to="/mieszkania/" className="content__btn btn">
                Zobacz mieszkania
              </Link>
            </div>
          </Fade>
        </div>
        <div className="col-12 col-xl-7 col-xxl-7 apartment-info-section__image-holder">
          <Fade delay={150} direction={"right"} triggerOnce>
            <img
              data-src={require("../assets/img/home-apartment.png")}
              alt=""
              className="apartment-info-section__image lazyload"
            />
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default ApartmentInfoSection
