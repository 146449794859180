import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutInvestment = () => (
  <section className="about-investment">
    <div className="container">
      <div className="row about-investment__top-info">
        <div className="col-12 col-xl2-5 offset-xl2-1">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="top-info__title">O inwestycji</h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-6 col-xl2-4 offset-lg-3 offset-xl2-1">
          <Fade delay={150} direction={"right"} triggerOnce>
            <p className="top-info__content">
              Budynek mieszkalny <span>KW51</span> to jedyna w swoim rodzaju
              inwestycja w Krakowie. Lokalizacja w samym sercu miasta to
              codzienny komfort i wygoda dla żyjących aktywnie. Unikalny design
              inspirowany kulturą japońską oraz regułami feng shui sprzyja
              wyciszeniu w tętniącym życiem mieście.
            </p>
          </Fade>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-12 col-xxl-10 offset-lg-1 offset-xl-0 offset-xxl-1 about-investment__items-holder">
          <div className="about-investment__item">
            <Fade delay={250} direction={"top"} triggerOnce>
              <div className="item__icon-holder mb-40">
                <img
                  data-src={require("../assets/img/investment-icon1.svg")}
                  alt="Lokalizacja"
                  className="item__icon lazyload"
                />
              </div>
              <h3 className="item__title mb-30">
                Lokalizacja w samym <br />
                centrum Krakowa
              </h3>
              <p className="item__content">
                Sklepy, parki, Stare Miasto, biurowce, siłownie, kina - wszystko
                to (i znacznie więcej) znajduje się tuż o krok!
              </p>
            </Fade>
          </div>
          <div className=" about-investment__item">
            <Fade delay={500} direction={"top"} triggerOnce>
              <div className="item__icon-holder mb-40">
                <img
                  data-src={require("../assets/img/investment-icon2.svg")}
                  alt="Lokalizacja"
                  className="item__icon lazyload"
                />
              </div>
              <h3 className="item__title mb-30">
                Design inspirowany <br />
                Japonią
              </h3>
              <p className="item__content">
                Projekt inspirowany jest kulturą japońską oraz regułami feng
                shui. Wszystko po to, by zapewnić przyszłym Lokatorom spokój i
                harmonię.
              </p>
            </Fade>
          </div>
          <div className="about-investment__item">
            <Fade delay={750} direction={"top"} triggerOnce>
              <div className="item__icon-holder mb-40">
                <img
                  data-src={require("../assets/img/investment-icon3.svg")}
                  alt="Lokalizacja"
                  className="item__icon lazyload"
                />
              </div>
              <h3 className="item__title mb-30">
                Wysoki standard <br />
                wykończenia
              </h3>
              <p className="item__content">
                KW51 to inwestycja, w której starannie dopracowany jest każdy,
                najdrobniejszy detal. Ponad wszystko cenimy estetykę i
                dokładność wykończenia.
              </p>
            </Fade>
          </div>
          <div className="about-investment__item">
            <Fade delay={250} direction={"top"} triggerOnce>
              <div className="item__icon-holder mb-40">
                <img
                  data-src={require("../assets/img/investment-icon4.svg")}
                  alt="Lokalizacja"
                  className="item__icon lazyload"
                />
              </div>
              <h3 className="item__title mb-30">
                Strefa zen - Twoja przestrzeń
                <br />
                odpoczynku
              </h3>
              <p className="item__content">
                W obiekcie znajduje się zielony dziedziniec oraz przestrzeń do
                jogi, medytacji i innych form aktywnego relaksu. To jedyny taki
                budynek mieszkalny w Krakowie!
              </p>
            </Fade>
          </div>
          <div className="about-investment__item">
            <Fade delay={500} direction={"top"} triggerOnce>
              <div className="item__icon-holder mb-40">
                <img
                  data-src={require("../assets/img/investment-icon5.svg")}
                  alt="Lokalizacja"
                  className="item__icon lazyload"
                />
              </div>
              <h3 className="item__title mb-30">
                W pełni funkcjonalne
                <br />
                mieszkania
              </h3>
              <p className="item__content">
                Poszczególne lokale zostały zaprojektowane w ten sposób, by
                maksymalnie wykorzystywać dostępną przestrzeń. Nie brakuje w
                nich dużej ilości światła i designerskich rozwiązań.
              </p>
            </Fade>
          </div>
          <div className="about-investment__item">
            <Fade delay={750} direction={"top"} triggerOnce>
              <div className="item__icon-holder mb-40">
                <img
                  data-src={require("../assets/img/investment-icon6.svg")}
                  alt="Lokalizacja"
                  className="item__icon lazyload"
                />
              </div>
              <h3 className="item__title mb-30">Zielona okolica</h3>
              <p className="item__content">
                Tuż za granicą inwestycji znajduje się popularna Młynówka
                Królewska, czyli 4,5 km tras spacerowo- rowerowych. 15 minut
                spacerem dzieli budynek od Parku Jordana i krakowskich błoń. 900
                metrów od inwestycji znajduje się Park Krakowski im. Marka
                Grechuty o powierzchni 4,77 ha.
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default AboutInvestment
